import styled from 'styled-components';
import HeroSection from './HeroSection';

const AboutSectionContainer = styled(HeroSection)`
  min-height: 100vh;
  
`;

export default AboutSectionContainer;

